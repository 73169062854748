.logo {
  display: block;
  height: 2.25rem;
  color: #17b978 !important;
  font-size: 1.2em;
  text-decoration: none;
  font-weight: 900;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  line-height: 2.2rem;
}

.logo:hover .logo__inner {
  padding-left: 0.375rem;
}

.logo__inner {
  display: inline-block;
  color: #171c28;
  transition: padding ease 0.2s;
}

.html--to-animate .fade-up {
  transform: translateY(1.5rem);
  opacity: 0;
  transition: all 1s ease;
}

.html--loaded.html--to-animate .fade-up {
  transform: translateY(0);
  opacity: 1;
}

.html--loaded.html--to-animate .fade-up-1 {
  transition-delay: 0;
}

.html--loaded.html--to-animate .fade-up-2 {
  transition-delay: 0.2s;
}

.html--loaded.html--to-animate .fade-up-3 {
  transition-delay: 0.4s;
}

.html--loaded.html--to-animate .fade-up-4 {
  transition-delay: 0.6s;
}

.html--loaded.html--to-animate .fade-up-5 {
  transition-delay: 0.8s;
}

.html--loaded.html--to-animate .fade-up-6 {
  transition-delay: 1s;
}

.html--loaded.html--to-animate .fade-up-7 {
  transition-delay: 1.2s;
}

.html--loaded.html--to-animate .fade-up-8 {
  transition-delay: 1.4s;
}

@media only screen and (max-width: 37.4375em) {
  .html--loaded.html--to-animate .fade-up-2 {
    transition-delay: 0s;
  }

  .html--loaded.html--to-animate .fade-up-6 {
    transition-delay: 0.4s;
  }

  .html--loaded.html--to-animate .fade-up-7 {
    transition-delay: 0.6s;
  }

  .html--loaded.html--to-animate .fade-up-8 {
    transition-delay: 0.8s;
  }
}

.social-media-div {
    margin-block-start: 0.5em;
    font-size: 2em;
}

.icon-button {
    margin-bottom: 10px;
}

.icon-button i {
    color: #fafafa;
    border-radius: 2.5rem;
    cursor: pointer;
    display: inline-block;
    font-size: 1.25rem;
    height: 2.5rem;
    line-height: 2.5rem;
    margin: 0 5px;
    position: relative;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 2.5rem;
    margin-bottom: 10px;
}


.discord i {
    background-color: #7289da;
}

.xing i {
    background-color: #cfdc00;
}

.facebook i {
    background-color: #3b5998;
}

.linkedin i {
    background-color: #0e76a8;
}

.github i {
    background-color: #24292e;
}

.gitlab i {
    background-color: #fca326;
}

.google i {
    background-color: #ea4335;
}

.twitter i {
    background-color: #1da1f2;
}

.instagram i {
    background-color: #c13584;
}

.telegram i {
    background-color: #0088cc;
}

@media (max-width: 768px) {
    .social-media-div {
        text-align: center;
    }
}

@keyframes rot {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.icon-spin:hover,
.icon-spin:active {
    animation-name: rot;
    animation-duration: 0.8s;
    animation-iteration-count: 1;
}
::-moz-selection {
  background: #17b978;
  color: #fafafa;
  text-shadow: none;
}

::selection {
  background: #17b978;
  color: #fafafa;
  text-shadow: none;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

html {
  overflow: scroll;
  overflow-x: hidden;
  font-size: 16px;
}

body {
  transition: all 0.5s linear;
}

p {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}
